import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

import AppRoutes from './AppRoutes';  // Import the AppRoutes component

registerCoreBlocks();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AppRoutes />
    </Router>
  </React.StrictMode>
);
