
export const convertFormat = (data) => {
  const convertedData = {};
  for (let q_id in data.answers) {
    let q_data = data.answers[q_id];
    let value = Array.isArray(q_data.value) ? q_data.value[0] : q_data.value;
    convertedData[q_id] = value;
  }
  return convertedData;
};


export const prepareWebhookData = (convertedData) => {
  let data = {
    name: null,
    phone: null,
    other_fields: {}
  };

  // Assuming that the 'name' and 'phone' fields are identifiable by specific question ids
  // For example, let's say 'q1' corresponds to 'name' and 'q2' corresponds to 'phone'
  for (let key in convertedData) {
    switch(key) {
      case 'name': // Assuming 'q1' is the identifier for 'name'
        data.name = convertedData[key];
        break;
      case 'phone': // Assuming 'q2' is the identifier for 'phone'
        data.phone = convertedData[key];
        break;
      default:
        // All other fields are grouped under 'other_fields'
        data.other_fields[key] = convertedData[key];
    }
  }

  return data;
};

export const submitPrivrWebhook = (data, config) => {
  return new Promise((resolve, reject) => {
    if (config.mode === 'dev') {
      // Simulate an API call with a delay
      setTimeout(() => {
        if (config.mockSuccess) {
          resolve('Success');
        } else {
          reject(new Error('Failed to submit the form in development mode.'));
        }
      }, 1000); // simulate a delay as if an API call was made
    } else {
      // Send data using Fetch API
      fetch("https://www.privyr.com/api/v1/incoming-leads/0vZfjMQw/Tk4OqHzs#generic-webhook", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          resolve('Success');
        } else {
          reject(new Error('Failed to submit the form.'));
        }
      })
      .catch(error => {
        reject(new Error('Failed to submit the form due to network or server issue.'));
      });
    }
  })
};

