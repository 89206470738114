import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRouteTracker } from './useRouteTracker';  // Make sure the hook is imported correctly

import { MoleRemovalForm } from './MoleRemovalForm';
import { EarLobeRepairForm } from './EarLobeRepairForm';
import { CystRemovalForm } from './CystRemovalForm';
import { ChildBurnsSpecialistForm } from './ChildBurnsSpecialistForm';
import { ScarRevisionForm } from './ScarRevisionForm';
import { DogBiteForm } from './DogBiteForm';
import { BlepharoplastyForm } from './BlepharoplastyForm';
import { HymenoplastyForm } from './HymenoplastyForm';
import { LabiaPlastyForm } from './LabiaPlastyForm';
import { VaginoPlastyForm } from './VaginoPlastyForm';
import { AbdominoplastyForm } from './AbdominoplastyForm';
import { BreastAugmentationForm } from './BreastAugmentationForm';
import { GynaecomastiaForm } from './GynaecomastiaForm';

const AppRoutes = () => {
  useRouteTracker();  // Use the hook here

  return (
    <Routes>
      <Route path="/mole-removal" element={<MoleRemovalForm />} />
      <Route path="/ear-lobe-repair" element={<EarLobeRepairForm />} />
      <Route path="/cyst-removal" element={<CystRemovalForm />} />
      <Route path="/child-burn" element={<ChildBurnsSpecialistForm />} />
      <Route path="/scar-revision" element={<ScarRevisionForm />} />
      <Route path="/dog-bite" element={<DogBiteForm />} />
      <Route path="/blepharoplasty" element={<BlepharoplastyForm />} />
      <Route path="/hymenoplasty" element={<HymenoplastyForm />} />
      <Route path="/labiaplasty" element={<LabiaPlastyForm />} />
      <Route path="/vaginoplasty" element={<VaginoPlastyForm />} />
      <Route path="/tummy-tuck" element={<AbdominoplastyForm />} />
      <Route path="/breast_augmentation" element={<BreastAugmentationForm />} />
      <Route path="/gynaecomastia" element={<GynaecomastiaForm />} />
    </Routes>
  );
};

export default AppRoutes;
