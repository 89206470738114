import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/blepharoplasty.webp'; // Ensure you have an appropriate image

export const BlepharoplastyForm = () => {
  useEffect(() => {
    document.title = 'Leading Eyelid Surgery Specialist'; // Updated title
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <Form
        formId="2" // Updated formId
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "bph1001r",
              attributes: {
                buttonText: "Next",
                label: "<b>Leading Blepharoplasty Surgeon in Gurgaon<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>
                MBBS, MS, MCh.<br>
                Plastic & Reconstructive Surgery<br>
                <h2>Revitalize Your Appearance!</h2>
                Safe & Effective Procedure&nbsp;&nbsp; ✅<br>
                Tailored Approach for Every Patient&nbsp;&nbsp;✅<br>
                Rapid Recovery&nbsp;&nbsp;✅<br>
                `,
                attachment: {
                  type: "image",
                  url: image
                },
                attachmentMaxWidth: "300px"
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_issue",
              "attributes": {
                "required": true,
                "label": "What issue are you looking to address?",
                "choices": [
                  {"label": "Droopy eyelids", "value": "droopy_eyelids"},
                  {"label": "Under eye bags", "value": "under_eye_bags"},
                  {"label": "Wrinkles around eyes", "value": "wrinkles"},
                  {"label": "Vision obstruction", "value": "vision_obstruction"},
                  {"label": "A mix of everything", "value": "a_mix_of_everything"},
                  {"label": "Others", "value": "others"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_age_group",
              "attributes": {
                "required": true,
                "label": "What's your age group?",
                "choices": [
                  {"label": "18-30 years", "value": "18_30"},
                  {"label": "31-45 years", "value": "31_45"},
                  {"label": "46-60 years", "value": "46_60"},
                  {"label": "Above 60 years", "value": "above_60"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_interest_level",
              "attributes": {
                "label": "How interested are you in undergoing blepharoplasty?",
                "choices": [
                  {"label": "Just exploring", "value": "exploring"},
                  {"label": "Somewhat interested", "value": "somewhat_interested"},
                  {"label": "Very interested", "value": "very_interested"},
                  {"label": "Ready to book a consultation", "value": "ready_to_book"}
                ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                required: true,
                description: "We value your trust and privacy. Your phone number will be securely shared with our doctor for necessary communication only!",
                label: "What's your phone number?"
              }
            },
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thank you! We will contact you shortly!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'blepharoplasty'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
