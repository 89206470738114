import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/elr.webp';

export const EarLobeRepairForm = () => {
  useEffect(() => {
    document.title = 'Best Ear Lobe Repair Surgeon in Gurgaon!'; // Set the desired title
  }, []); //
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "jg1401r",
              attributes: {
                buttonText: "Next",
                label: "<b>Best Ear Lobe Repair Surgeon in Gurgaon!<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>
                MBBS, MS, MCh.<br>
                Plastic & Reconstructive Surgery
                <h2>Say GoodBye to Damaged Ear Lobes!</h2>
                Quick & Painless Procedure&nbsp;&nbsp; ✅<br>
                Long-Lasting, Natural Looking Results&nbsp;&nbsp;✅<br>
                Fast Recovery&nbsp;&nbsp;✅<br>
                Affordable Treatment&nbsp;&nbsp;✅
                `,
                attachment: {
                  type: "image",
                  url: image
                },
                attachmentMaxWidth: "250px"
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_age_group",
              "attributes": {
                "required": true,
                "label": "What age group are you in?",
                "description": "This is going to help us make the best recommendations to you",
                "choices": [
                  {
                    "label": "Below 18",
                    "value": "below_18"
                  },
                  {
                    "label": "18-30",
                    "value": "18_to_30"
                  },
                  {
                    "label": "31-50",
                    "value": "31_to_50"
                  },
                  {
                    "label": "51-65",
                    "value": "51_to_65"
                  },
                  {
                    "label": "Above 65",
                    "value": "above_65"
                  }
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_location",
              "attributes": {
                "label": "Where are you located?",
                "description": "This is going to help us make the best recommendations to you",
                "choices": [
                  {
                    "label": "Gurgaon",
                    "value": "gurgaon"
                  },
                  {
                    "label": "Delhi",
                    "value": "delhi"
                  },
                  {
                    "label": "Faridabad",
                    "value": "faridabad"
                  },
                  {
                    "label": "Ghaziabad",
                    "value": "ghaziabad"
                  },
                  {
                    "label": "Other",
                    "value": "other"
                  }
                ]
              },
            },
            {
              "name": "multiple-choice",
              "id": "q_when",
              "attributes": {
                "label": "How soon are you looking for a treatment?",
                "choices": [
                  {
                    "label": "As soon as possible",
                    "value": "asap"
                  },
                  {
                    "label": "Within a week",
                    "value": "within_a_week"
                  },
                  {
                    "label": "Within a month",
                    "value": "within_a_month"
                  },
                  {
                    "label": "> 1 month",
                    "value": "more_than_a_month"
                  },
                  {
                    "label": "Not sure",
                    "value": "not_sure"
                  }
                ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                classnames: "first-block",
                required: true,
                description: "We value your trust and privacy. Your phone number will be securely shared with our doctor for necessary communication only!",
                label: "What's your phone number?"
              }
            },
            
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thanks! We\'ll be in touch soon!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'ear_lobe_repair'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
