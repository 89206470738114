// File: useRouteTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useRouteTracker() {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageView',
      pagePath: location.pathname,
      pageTitle: document.title || location.pathname
    });
  }, [location]);
}
