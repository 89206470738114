import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/breast_augmentation.webp'; // Ensure you have an appropriate image

export const BreastAugmentationForm = () => {
  useEffect(() => {
    document.title = 'Top Breast Augmentation Specialist in Gurgaon'; // Updated title
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <Form
        formId="2" // Updated formId
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "bph1001r",
              attributes: {
                buttonText: "Next",
                label: "<b>Top Breast Augmentation Specialist in Gurgaon<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>MBBS, MS, MCh.<br>Plastic & Reconstructive Surgery<br><h2>Enhance Your Beauty with Breast Augmentation!</h2>Safe & Customized Procedures&nbsp;&nbsp;✅<br>Minimal Scarring & Natural Results&nbsp;&nbsp;✅<br>Comprehensive Post-Op Care&nbsp;&nbsp;✅<br>State-of-the-Art Clinic Facilities&nbsp;&nbsp;✅`,
                attachment: {
                  type: "image",
                  url: image
                },
                attachmentMaxWidth: "300px"
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_exploration_reason",
              "attributes": {
                "required": true,
                "label": "Why are you exploring Breast Augmentation?",
                "choices": [
                  {"label": "Enhance breast size", "value": "enhance_size"},
                  {"label": "Correct asymmetry", "value": "correct_asymmetry"},
                  {"label": "Restore breast shape after pregnancy", "value": "restore_shape"},
                  {"label": "Medical reasons", "value": "medical"},
                  {"label": "Other", "value": "other"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_current_cup_size",
              "attributes": {
                "required": true,
                "label": "What is your current cup size?",
                "choices": [
                  {"label": "A cup", "value": "a_cup"},
                  {"label": "B cup", "value": "b_cup"},
                  {"label": "C cup", "value": "c_cup"},
                  {"label": "D cup", "value": "d_cup"},
                  {"label": "E cup or above", "value": "e_cup_above"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_implant_preference",
              "attributes": {
                "required": true,
                "label": "Are you considering silicone or saline implants?",
                "choices": [
                  {"label": "Silicone implants", "value": "silicone_implants"},
                  {"label": "Saline implants", "value": "saline_implants"},
                  {"label": "Not sure", "value": "not_sure"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_previous_surgeries",
              "attributes": {
                "required": true,
                "label": "Have you had any previous breast surgeries?",
                "choices": [
                  {"label": "Yes", "value": "yes"},
                  {"label": "No", "value": "no"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_urgency",
              "attributes": {
                "required": true,
                "label": "How soon are you looking to undergo Breast Augmentation?",
                "choices": [
                  {"label": "Asap", "value": "asap"},
                  {"label": "Within a month", "value": "within_a_month"},
                  {"label": "Within 3-6 months", "value": "within_3_6_months"},
                  {"label": "Undecided", "value": "undecided"}
                ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                required: true,
                description: "We value your trust and privacy. Your phone number will be securely shared with our doctor for necessary communication only!",
                label: "What's your phone number?"
              }
            },
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thank you! We will contact you shortly!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'breast_augmentation'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
